<section class="slick-slider-wrap-layout1">
    <div class="slick-slider-layout1">
        <div class="slick-carousel slick-content" data-slick='{
            "slidesToShow": 1,
            "slidesToShowTab": 1,
            "slidesToShowMobile": 1,
            "slidesToShowMobiles": 1,
            "slidesToScroll": 1,
            "speed": 1000,
            "autoplaySpeed": 1700,
            "dots": false,
            "arrows": false,
            "lazyLoad": "progressive",
            "pauseOnHover": false,
            "autoplay": true,
            "centerMode": true,
            "adaptiveHeight": false,
            "rtl": false,
            "asNavFor": ".slick-nav"
            }'>
            <div class="slick-slider">
                <div class="item-img">
                    <img src="./assets/img/inicio/industrial_fengari.jpg" alt="Industrial | Fengari">
                    <div class="item-content">
                        <div class="big-text">Industrial</div>
                    </div>
                </div>
            </div>
            <div class="slick-slider">
                <div class="item-img">
                    <img src="./assets/img/inicio/mineria_fengari.jpg" alt="Mineria | Fengari">
                    <div class="item-content">
                        <div class="big-text">Minería</div>
                    </div>
                </div>
            </div>
            <div class="slick-slider">
                <div class="item-img">
                    <img src="./assets/img/inicio/comercial_fengari.jpg" alt="Comerial | Fengari">
                    <div class="item-content">
                        <div class="big-text">Comercial</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slick-nav-wrap">
            <div class="container">
                <div class="slick-carousel slick-nav" data-slick='{
                    "slidesToShow": 5,
                    "slidesToShowTab": 3,
                    "slidesToShowMobile": 3,
                    "slidesToShowMobiles": 1,
                    "slidesToScroll": 2,
                    "speed": 1000,
                    "autoplaySpeed": 1700,
                    "dots": false,
                    "arrows": false,
                    "lazyLoad": "progressive",
                    "pauseOnHover": true,
                    "autoplay": true,
                    "focusOnSelect": true,
                    "centerMode": false,
                    "adaptiveHeight": false,
                    "rtl": false,
                    "asNavFor": ".slick-content",
                    "prevArrow": "<span class=\"slick-prev slick-navigation\"><i class=\"flaticon-back\"></i></span>",
                    "nextArrow": "<span class=\"slick-next slick-navigation\"><i class=\"flaticon-next\"></i></span>"
                    }'>
                    <div class="nav-item">
                        <div class="item-icon">
                            <i class="flaticon-factory"></i>
                        </div>
                        <h4 class="item-title">Industrial</h4>
                    </div>
                    <div class="nav-item">
                        <div class="item-icon"> 
                            <i class="flaticon-coal"></i>
                        </div>
                        <h4 class="item-title">Minería</h4>
                    </div>
                    <div class="nav-item">
                        <div class="item-icon">
                            <i class="flaticon-shop"></i>
                        </div>
                        <h4 class="item-title">Comerial</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>