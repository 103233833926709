<section class="single-project-wrap-layout">
    <div class="container">
        <div class="single-project-box-layout1">
            <div class="single-project-info">
                <div class="item-content">
                    <h1 class="heading-layout1">Bombas sanitarias</h1>
                </div>
                <div class="row justify-content-between">
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">Alfa Laval</h3>
                            <p> Bombas centrífugas Alfa Laval LKH rentables para aplicaciones de evaporación, 
                                alta presión, autocebado y alta pureza. Diseñada para cumplir con los requisitos 
                                higiénicos de las industrias lácteas, de alimentos y bebidas y de cuidado personal.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_sanitarias/alfa_laval/logo_alfa_laval.png" alt="logo_alfa_laval | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_sanitarias/alfa_laval/bs_alfa_laval.jpg" alt="bs_alfa_laval | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_sanitarias/alfa_laval/bsa_laval.jpg" alt="bsa_laval | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_sanitarias/alfa_laval/lhk_pump_range_alfa_laval.jpg" alt="lhk_pump_range_alfa_laval | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_sanitarias/alfa_laval/LKH_alfa_laval.png" alt="LKH_alfa_laval | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_sanitarias/alfa_laval/unique_ssv_reverse_acting_valve_right_side_alfa_laval.png" alt="unique_ssv_reverse_acting_valve_right_side_alfa_laval | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">Puriti</h3>
                            <p> Esta bomba ofrece un gran rendimiento y una capacidad 
                                económicamente competitiva dentro de un espectro de bombas centrífugas 
                                para trabajos pesados con menos aplicaciones. Se puede instalar en serie 
                                sin que fallen las juntas por las elevadas presiones de entrada, 
                                incluso con productos viscosos.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_sanitarias/puriti/logo_puriti.png" alt="logo_puriti | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_sanitarias/puriti/bomba_sanitaria_puriti.png" alt="bomba_sanitaria_puriti | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 offset-md-9">
                        <div class="about-box-layout4">
                            <div class="about-box-content">
                                <a href="./productos" class="ghost-btn-lg primary-border text-Primary mg-t-15"><i class="fas fa-angle-left"></i> Regresar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>