<!-- Footer Area Start Here -->
<footer class="footer-wrap-layout1">
    <div class="footer-top-wrap-layout1">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="footer-box-layout1">
                        <div class="footer-logo">
                            <a href="index.html"><img src="./assets/img/Logo_HD_Fengari_SA_FondoBlanco.jpg" alt="Logo_footer | Fengari"></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="footer-box-layout1">
                        <div class="footer-menu-box">
                            <p>Fengari S.A. de C.V. 1er Avenida # 108, Col Guerra, Guadalupe, N.L. C.P. 67140 Tel: (81) 8398-7762</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="footer-box-layout1">
                        <div class="footer-title">
                            <h3>Mapa del Sitio</h3>
                        </div>
                        <div class="footer-menu-box">
                            <ul class="footer-menu-list">
                                <li>
                                    <a href="./home">Inicio</a>
                                </li>
                                <li>
                                    <a href="./nosotros">Sobre Nosotros</a>
                                </li>
                                <li>
                                    <a href="./productos">Productos</a>
                                </li>
                            </ul>
                            <ul class="footer-menu-list">
                                <li>
                                    <a href="./servicios">Servicios</a>
                                </li>
                                <li>
                                    <a href="./contacto">Contacto</a>
                                </li>
                            </ul>
                            <ul class="footer-menu-list">
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-wrap-layout1">
        <div class="copyright">Copyright © 2020 Fengari by <a href="https://www.softseti.com/" target="_blank">Softseti</a> Todos los derechos reservados.</div>
    </div>
</footer>
<!-- Footer Area End Here -->
