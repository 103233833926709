import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intercambiador-calor',
  templateUrl: './intercambiador-calor.component.html',
  styleUrls: ['./intercambiador-calor.component.css']
})
export class IntercambiadorCalorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
