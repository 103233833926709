import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maps-info',
  templateUrl: './maps-info.component.html',
  styleUrls: ['./maps-info.component.css']
})
export class MapsInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
