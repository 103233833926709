<section class="contact-wrap-layout2">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="contact-box-layout2">
                    <div class="contact-form">
                        <div class="heading-layout3">
                            <h3 class="mg-b-10">Contactanos</h3>
                        </div>
                        <form class="contact-form-box" id="contact-form">
                            <div class="row gutters-15">
                                <div class="col-md-6 col-12 form-group">
                                    <input type="text" placeholder="Nombre *" class="form-control" name="name" data-error="Name field is required" required>
                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="col-md-6 col-12 form-group">
                                    <input type="email" placeholder="E-mail *" class="form-control" name="email" data-error="email field is required" required>
                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="col-md-6 col-12 form-group">
                                    <input type="text" placeholder="Empresa *" class="form-control" name="phone" data-error="Phone field is required" required>
                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="col-md-6 col-12 form-group">
                                    <input type="text" placeholder="Telefono *" class="form-control" name="subject" data-error="Subject field is required" required>
                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="col-12 form-group">
                                    <textarea placeholder="Comentarios *" class="textarea form-control" name="message" id="form-message" rows="7" cols="20" 
                                    data-error="Message field is required" required></textarea>
                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="col-12 form-group">
                                    <button type="submit" class="btn-fill-xl bg-Primary text-textprimary border-none">Enviar</button>
                                </div>
                            </div>
                            <div class="form-response"></div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 sidebar-break-md sidebar-widget-area">
                <div class="widget widget-banner">
                    <div class="banner-img">
                        <img src="../../assets/img/figure/widget-banner.jpg" alt="Banner">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
