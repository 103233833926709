<section class="single-project-wrap-layout">
    <div class="container">
        <div class="single-project-box-layout1">
            <div class="single-project-info">
                <div class="item-content">
                    <h1 class="heading-layout1">Bombas Centrifugas</h1>
                </div>
                <div class="row justify-content-between">
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">Barnes</h3>
                            <p>Cuentan con una variedad de modelos, 
                                cada uno diseñado para las diferentes 
                                necesidades de la industria y el público en general.</p>
                            <ul class="list-item">
                                <li><i class="fas fa-check"></i>Alta presión, Serie IA</li>
                                <li><i class="fas fa-check"></i>Mediana presión,Series IB & IC</li>
                                <li><i class="fas fa-check"></i>Acero Inoxidable, Series PS & CD</li>
                                <li><i class="fas fa-check"></i>Verticales en Línea</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/barnes/logo_barnes.jpg" alt="Logo_Barnes | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/barnes/autocebantes_barnes.jpg" alt="autocebantes_barnes  | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/barnes/equipo_fps_barnes.jpg" alt="equipo_fps_barnes | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/barnes/serie-ia_barnes.jpg" alt="serie-ia_barnes | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/barnes/serie-ib_barnes.jpg" alt="serie-ib_barnes | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">Durcomex</h3>
                            <p>Línea de bombas centrífugas tipo ANSI bajo la calidad y la experiencia de la empresa antes Durco ahora Durcomex.</p>
                            <ul class="list-item">
                                <li><i class="fas fa-check"></i>Series 1L, 2L & 3L</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/durcomex/logo_durcomex.png" alt="logo_durcomex | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">EVANS</h3>
                            <p>La marca EVANS es una excelente opcion para equipos comerciales, 
                                es utilizada ampliamente en la industria de la construccion y la industria en general.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/evans/logo_evans.png" alt="logo_evans | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/evans/bomba_centrifuga_34_hp_evans.jpg" alt="bomba_centrifuga_34_hp_evans | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/evans/bombas_de_superficie_evans.jpg" alt="bombas_de_superficie_evans | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/evans/bombas_de_superficie_XH2ME0200A_evans.jpg" alt="bombas_de_superficie_XH2ME0200A_evans | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">Goulds</h3>
                            <p>Maneja un amplio catálogo de bombas para condiciones 
                                demandantes desde equipos en hierro fundido hasta equipos en acero inoxidable</p>
                            <ul class="list-item">
                                <li><i class="fas fa-check"></i>Acero Inoxidable, Grupo-M</li>
                                <li><i class="fas fa-check"></i>G&L Series NPO</li>
                                <li><i class="fas fa-check"></i>ICS/ICSF & 3657/3757</li>
                                <li><i class="fas fa-check"></i>G&L Series NPE</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/goulds_xylem/logo_goulds.png" alt="logo_goulds | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/goulds_xylem/3657_goulds.jpg" alt="3657_goulds | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/goulds_xylem/NPEPump_goulds.jpg" alt="NPEPump_goulds | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/goulds_xylem/SSH_wBIABadge_goulds.jpg" alt="SSH_wBIABadge_goulds | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">Pedrollo</h3>
                            <p>Bombas de fabricación italiana con un gran catálogo de modelos</p>
                            <ul class="list-item">
                                <li><i class="fas fa-check"></i>Centrifugas, Series CP, GA, HF & F</li>
                                <li><i class="fas fa-check"></i>Multietapas, Series 2CP & 3-4CP</li>
                                <li><i class="fas fa-check"></i>Acero Inoxidable, Series 3-4CR & JCR</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/pedrollo/logo_pedrollo.png" alt="logo_pedrollo | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/pedrollo/bomba_f_80160a_30_hp_normalizada_pedrollo.jpg" alt="bomba_f_80160a_30_hp_normalizada_pedrollo | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/pedrollo/CP_pedrollo.jpg" alt="CP_pedrollo | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/pedrollo/FG_pedrollo.jpg" alt="FG_pedrollo | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/pedrollo/HF_pedrollo.jpg" alt="HF_pedrollo | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">WDM</h3>
                            <p>Las marca WDM cuenta con bombas centrifugas de todo tipo, desde bombas caseras, 
                                pasando por residenciales, comerciales, industriales, hasta llegar a bombas de proceso ANSI.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/WDM/logo_wdm.jpg" alt="logo_wdm | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/WDM/alta_presion_wdm.jpg" alt="alta_presion_wdm | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/WDM/autocebantes_wdm.png" alt="autocebantes_wdm | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_centrifugas/WDM/mediana_presion_wdm.png" alt="mediana_presion_wdm | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-3 offset-md-9">
                        <div class="about-box-layout4">
                            <div class="about-box-content">
                                <a href="./productos" class="ghost-btn-lg primary-border text-Primary mg-t-15"><i class="fas fa-angle-left"></i> Regresar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>