import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent }   from './home/home.component';
import { NosotrosComponent }   from './nosotros/nosotros.component';
import { ProductosComponent }   from './productos/productos.component';
import { ServiciosComponent }   from './servicios/servicios.component';
import { ContactoComponent }   from './contacto/contacto.component';
import { BombasCentrifugasComponent }   from './bombas-centrifugas/bombas-centrifugas.component';
import { BombasDesplazamientoComponent }   from './bombas-desplazamiento/bombas-desplazamiento.component';
import { BombasDiafragmaComponent }   from './bombas-diafragma/bombas-diafragma.component';
import { BombasSanitariasComponent }   from './bombas-sanitarias/bombas-sanitarias.component';
import { BombasVacioComponent }   from './bombas-vacio/bombas-vacio.component';
import { IntercambiadorCalorComponent }   from './intercambiador-calor/intercambiador-calor.component';
import { MotoresComponent }   from './motores/motores.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'nosotros', component: NosotrosComponent },
  { path: 'productos', component: ProductosComponent },
  { path: 'servicios', component: ServiciosComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'producto/bombas-centrifugas', component: BombasCentrifugasComponent },
  { path: 'producto/bombas-desplazamiento', component: BombasDesplazamientoComponent },
  { path: 'producto/bombas-diafragma', component: BombasDiafragmaComponent },
  { path: 'producto/bombas-sanitarias', component: BombasSanitariasComponent },
  { path: 'producto/bombas-vacio', component: BombasVacioComponent },
  { path: 'producto/intercambiador-calor', component: IntercambiadorCalorComponent },
  { path: 'producto/motores', component: MotoresComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
