export const productList = [
    {
      name: 'Bombas Centrifugas',
      description: 'Es tal vez el tipo de bomba más fácil de identificar, ya que es utilizada en gran parte de las aplicaciones que existen, estas bombas se suelen montar horizontales, pero también pueden estar verticales. Se utilizan para la transferencia de líquidos, aplicaciones con agua, procesos químicos, etc.',
      vista: 0,
      page: 'producto/bombas-centrifugas',
      alt: 'Bombas_Centrifugas | Fengari',
      img: './assets/img/productos/portada/bombas_centrifugas.jpg'
    },
    {
      name: 'Bombas de Desplazamiento Positivo',
      description: 'Se les llama así, ya que literalmente empuja el fluido, es utilizada en aplicaciones donde el fluido presenta cierta viscosidad. Son utilizadas para manejar aceites y melazas.',
      vista: 1,
      page: 'producto/bombas-desplazamiento',
      alt: 'Bombas_De_Desplazamiento | Fengari',
      img: './assets/img/productos/portada/bombas_desplazamiento.png'
    },
    {
      name: 'Bombas de Vacío',
      description: 'Son bombas que extraen moléculas gaseosas de un contenedor sellado, se utilizan para empaquetar productos al vacío, o retirar sustancias no deseadas de algún proceso.',
      vista: 0,
      page: 'producto/bombas-vacio',
      alt: 'Bombas_De_Vacio | Fengari',
      img: './assets/img/productos/portada/bombas_vacio.png'
    },
    {
      name: 'Intercambiador de Calor',
      description: 'Los intercambiadores de calor de placas y juntas consisten en una serie de placas corrugadas entre las placas de presión que soportan la presión de diseño. Las juntas realizan la función de sellado entre las placas. Para lograr el mayor rendimiento térmico los fluidos normalmente pasan a contracorriente a través de las palcas del intercambiador.',
      vista: 1,
      page: 'producto/intercambiador-calor',
      alt: 'Intercambiador_De_Calor | Fengari',
      img: './assets/img/productos/portada/intercambiador_calor.JPG'
    },
    {
      name: 'Bombas Sanitarias',
      description: 'Bombas de acero inoxidable excelentes para aplicaciones alimenticias y farmacéuticas. Se utilizan para manejo de alcoholes, bebidas, lácteos, vinos y licores, cerveza, agua purificada entre otras.',
      vista: 0,
      page: 'producto/bombas-sanitarias',
      alt: 'Bombas_Sanitarias | Fengari',
      img: './assets/img/productos/portada/bombas_sanitarias.jpg'
    },
    {
      name: 'Bombas de Diafragma',
      description: 'Son bombas de desplazamiento positivo que funcionan de manera neumática o eléctrica, son utilizadas principalmente por su resistencia a la corrosión y el hecho de que sean autocebantes.',
      vista: 1,
      page: 'producto/bombas-diafragma',
      alt: 'Bombas_De_Diafragma | Fengari',
      img: './assets/img/productos/portada/bombas_diafragma.jpg'
    },
    {
      name: 'Motores',
      description: 'Los motores utilizados en las bombas son parte fundamental para el funcionamiento de la bomba, principalmente se utilizan motores eléctricos aunque también los hay a gasolina.',
      vista: 0,
      page: 'producto/motores',
      alt: 'Motores | Fengari',
      img: './assets/img/productos/portada/motores.jpg'
    }
  ];
  
