<section class="single-project-wrap-layout">
    <div class="container">
        <div class="single-project-box-layout1">
            <div class="single-project-info">
                <div class="item-content">
                    <h1 class="heading-layout1">Bombas de desplazamiento positivo</h1>
                </div>
                <div class="row justify-content-between">
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">Blackmer</h3>
                            <p>Las bombas de paletas Blackmer, son bombas autocebantes que pueden bombear
                                productos de alta y baja densidad, ya sean corrosivos, derivados del petróleo, 
                                solventes, aceites, etc.</p>
                            <ul class="list-item">
                                <li><i class="fas fa-check"></i>HXL</li>
                                <li><i class="fas fa-check"></i>TX</li>
                                <li><i class="fas fa-check"></i>XLS</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_desplazamiento/blackmer/logo_blackmer.png" alt="logo_blackmer | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_desplazamiento/blackmer/desplazamiento_positivo_blackmer2.png" alt="desplazamiento_positivo_blackmer2 | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">ROPER</h3>
                            <p>Las bombas Roper son utilizadas en la industria quimica,
                                 petroquimica, asi como en el llenado y vaciado de carrotanques</p>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_desplazamiento/roper/logo_roper.png" alt="logo_roper | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_desplazamiento/roper/bd_roper.png" alt="bd_roper | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_desplazamiento/roper/bomba_roper.png" alt="bomba_roper | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">Vikinges</h3>
                            <p> líder mundial en soluciones para desplazamiento 
                                positivo, cuenta con un gran número de opciones, entre ellas:</p>
                            <ul class="list-item">
                                <li><i class="fas fa-check"></i>Engranes Internos o Externos</li>
                                <li><i class="fas fa-check"></i>Acoplamiento magnético</li>
                                <li><i class="fas fa-check"></i>Lóbulos</li>
                                <li><i class="fas fa-check"></i>Aplicaciones agresivas</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_desplazamiento/vikinges/logo_viking.jpg" alt="logo_viking | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_desplazamiento/vikinges/bd_viking.jpg" alt="bd_viking | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_desplazamiento/vikinges/stainless_steel_pumps_1_viking.png" alt="stainless_steel_pumps_1_viking | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 offset-md-9">
                        <div class="about-box-layout4">
                            <div class="about-box-content">
                                <a href="./productos" class="ghost-btn-lg primary-border text-Primary mg-t-15"><i class="fas fa-angle-left"></i> Regresar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>