<section class="inner-page-banner bg-common">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumbs-area">
                    <h1>{{titulo_banner}}</h1>
                    <ul>
                        <li>
                            <a href="./index.html">Inicio</a>
                        </li>
                        <li> {{titulo_banner}} </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>