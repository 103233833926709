import { BrowserModule, Title }  from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { SliderComponent } from './slider/slider.component';
import { BienvenidoComponent } from './bienvenido/bienvenido.component';
import { BannerComponent } from './banner/banner.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { MisionComponent } from './mision/mision.component';
import { VisionComponent } from './vision/vision.component';
import { ValoresComponent } from './valores/valores.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { ContactoComponent } from './contacto/contacto.component';
import { SectionProductsComponent } from './section-products/section-products.component';
import { MapsInfoComponent } from './maps-info/maps-info.component';
import { FormContactComponent } from './form-contact/form-contact.component';
import { SectionServiceComponent } from './section-service/section-service.component';
import { BombasCentrifugasComponent } from './bombas-centrifugas/bombas-centrifugas.component';
import { BombasDesplazamientoComponent } from './bombas-desplazamiento/bombas-desplazamiento.component';
import { BombasVacioComponent } from './bombas-vacio/bombas-vacio.component';
import { IntercambiadorCalorComponent } from './intercambiador-calor/intercambiador-calor.component';
import { BombasSanitariasComponent } from './bombas-sanitarias/bombas-sanitarias.component';
import { BombasDiafragmaComponent } from './bombas-diafragma/bombas-diafragma.component';
import { MotoresComponent } from './motores/motores.component';
import { ProductosComponent } from './productos/productos.component';
import { NosotrosComponent } from './nosotros/nosotros.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    SliderComponent,
    BienvenidoComponent,
    BannerComponent,
    QuienesSomosComponent,
    MisionComponent,
    VisionComponent,
    ValoresComponent,
    ServiciosComponent,
    ContactoComponent,
    SectionProductsComponent,
    MapsInfoComponent,
    FormContactComponent,
    SectionServiceComponent,
    BombasCentrifugasComponent,
    BombasDesplazamientoComponent,
    BombasVacioComponent,
    IntercambiadorCalorComponent,
    BombasSanitariasComponent,
    BombasDiafragmaComponent,
    MotoresComponent,
    ProductosComponent,
    NosotrosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
