<section class="about-wrap-layout7 bg-shape-4">
    <div class="container margen">
        <div class="about-box-layout9">
            <div class="single-item">
                <div class="item-content col-12">
                    <h2 class="item-title center">Valores</h2>
                </div>
            </div>
        </div>
        <div class="single-service-box-layout1">
            <div class="col-12 offset-md-3">
                <div class="item-content">
                    <div class="item-services">
                        <div class="service-list">
                            <ul>
                                <li><i class="fas fa-check"></i>Honestidad</li>
                                <li><i class="fas fa-check"></i>Responsabilidad</li>
                            </ul>
                            <ul>
                                <li><i class="fas fa-check"></i>Trabajo en equipo</li>
                                <li><i class="fas fa-check"></i>Respeto</li>
                            </ul>
                            <ul>
                                <li><i class="fas fa-check"></i>Comunicación</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>           
    </div>   
</section>

