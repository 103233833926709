import { Component, OnInit } from '@angular/core';
import { productList } from '../product-list';

@Component({
  selector: 'app-section-products',
  templateUrl: './section-products.component.html',
  styleUrls: ['./section-products.component.css']
})
export class SectionProductsComponent implements OnInit {
  productList = productList;
  constructor() {
   }
  
  ngOnInit(): void {
  }

}
