<section class="about-wrap-layout3 overflow-hidden">
    <div class="container" *ngFor="let product of productList">
        <div class="row" *ngIf="product.vista == 1"> 
            <div class="col-lg-6 order-lg-5">
                <div class="about-box-layout4">
                    <div class="about-box-img">
                        <div class="item-img">
                            <img src="{{product.img}}" alt="{{product.alt}}">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 order-lg-6">
                <div class="about-box-layout4">
                    <div class="about-box-content">
                        <h2 class="item-title">{{ product.name }}</h2>
                        <p>{{ product.description}}</p>
                        <a href="./{{product.page}}" class="ghost-btn-lg primary-border text-Primary mg-t-15">Ver mas<i class="fas fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="product.vista == 0">
            <div class="col-lg-6 order-lg-8">
                <div class="about-box-layout4">
                    <div class="about-box-img">
                        <div class="item-img">
                            <img src="{{product.img}}" alt="{{product.alt}}">
                        </div> 
                    </div>
                </div>
            </div>
            <div class="col-lg-6 order-lg-7">
                <div class="about-box-layout4">
                    <div class="about-box-content">
                        <h2 class="item-title">{{ product.name }}</h2>
                        <p>{{ product.description}}</p>
                        <a href="./{{product.page}}" class="ghost-btn-lg primary-border text-Primary mg-t-15">Ver mas<i class="fas fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>