<section class="single-project-wrap-layout">
    <div class="container">
        <div class="single-project-box-layout1">
            <div class="single-project-info">
                <div class="item-content">
                    <h1 class="heading-layout1">Intercambiadores de Calor</h1>
                </div>
                <div class="row justify-content-between">
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">Intercambiadores de calor a placas</h3>
                            <p> Facilitan el mantenimiento 
                                y esto a su vez prolonga la vida útil del equipo, la construcción 
                                del equipo depende los fluidos que manejara, pudiendo ser placas en 
                                Acero Inoxidable 304 o 306, como también se puede utilizar Titanio, 
                                otro factor importante es la temperatura ya que esta junto el tipo de 
                                fluido determina el material utilizado en los empaques, 
                                que pueden ser de EPDM, NBR o Viton.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/intercambiadores_calor/ic_fengari.jpg" alt="ic_fengari | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/intercambiadores_calor/intercambiado_calor_fengari.png" alt="intercambiado_calor_fengari | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 offset-md-9">
                        <div class="about-box-layout4">
                            <div class="about-box-content">
                                <a href="./productos" class="ghost-btn-lg primary-border text-Primary mg-t-15"><i class="fas fa-angle-left"></i> Regresar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>