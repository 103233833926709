<section class="about-wrap-layout7 bg-shape-4">
    <div class="container margen">
        <div class="about-box-layout9">
            <div class="single-item center">
                <div class="item-content" >
                    <h2 class="item-title">Misión</h2>
                    <p >Ofrecer equipos de bombeo e intercambiadores 
                    de calor los cuales sean adecuados para las 
                    necesidades del cliente, guiándonos bajo el 
                    principio de la honestidad y comunicación con el cliente.</p>
                </div>
            </div>
        </div>           
    </div>   
</section>