<section class="about-wrap-layout6">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-10 col-12">
                <div class="heading-layout1">
                    <h2>¿Quiénes somos?</h2>
                    <p> Somos una empresa mexicana comprometida a ofrecer soluciones 
                        integrales para sistemas de bombeo y de enfriamiento. Contamos
                        con una amplia gama de productos enfocados a satisfacer las 
                        necesidades específicas de nuestros clientes.</p>
                </div>
            </div>
        </div>
    </div>
</section>