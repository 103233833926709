<section class="about-wrap-layout6 bg-aash">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-11 col-12">
                <div class="about-box-layout8">
                    <div class="item-content bg-textprimary pseudo-textprimary">
                        <h1 class="item-title">Servicios</h1>
                        <ul class="list-item">
                            <li><i class="fas fa-check"></i>Selección de equipos de bombeo</li>
                            <li>Contamos con personal capacitado para apoyarlo en la selección de un equipo.</li>
                            <li><i class="fas fa-check"></i>Venta equipo nuevo</li>
                            <li>Le apoyamos en la búsqueda de equipo.</li>
                            <li><i class="fas fa-check"></i>Mantenimiento</li>
                            <li>Realizamos reparaciones de equipo, en nuestro taller con técnicos capacitados.</li>
                            <li><i class="fas fa-check"></i>Refacciones</li>
                            <li>Le apoyamos en la búsqueda de refacciones.</li>
                        </ul>
                    </div>
                    <div class="item-img">
                        <img src="./assets/img/servicios/servicios_fengari.jpg" alt="Servicios | Fengari">
                    </div> 
                </div>
            </div>
        </div>
    </div>
</section>