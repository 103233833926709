<section class="about-wrap-layout7 bg-shape-4">
    <div class="container margen">
        <div class="about-box-layout9">
            <div class="single-item center">
                <div class="item-content" >
                    <h2 class="item-title">Visión</h2>
                    <p>Convertirnos en sinónimo de equipos de 
                        bombeo e intercambiadores de calor en el 
                        norte del país, y expandir operaciones
                         al sur y centro de México.</p>
                </div>
            </div>
        </div>           
    </div>   
</section>

