<section class="contact-wrap-layout2" >
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="contact-box-layout2">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.8230751835267!2d-100.25388088500792!3d25.677159018361547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662c0025633ed09%3A0xee5945a1bead2d1e!2sFengari%20S.A.%20de%20C.V!5e0!3m2!1ses-419!2smx!4v1592515972111!5m2!1ses-419!2smx" 
                    width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </div>
            <div class="col-lg-4 sidebar-break-md sidebar-widget-area">
                <div class="widget widget-contact-info">
                    <div class="media">
                        <div class="item-icon">
                            <i class="flaticon-call-answer"></i>
                        </div>
                        <div class="media-body space-md">
                            <h4>Telefono:</h4>
                            <ul>
                                <li>(81) 8398-7762 </li>
                            </ul>
                        </div>
                    </div>
                    <div class="media">
                        <div class="item-icon">
                            <i class="flaticon-message"></i>
                        </div>
                        <div class="media-body space-md">
                            <h4>E-mail:</h4>
                            <ul>
                                <li>info@fengari.com.mx</li>
                            </ul>
                        </div>
                    </div>
                    <div class="media">
                        <div class="item-icon">
                            <i class="flaticon-maps-and-flags"></i>
                        </div>
                        <div class="media-body space-md">
                            <h4>Ubicación:</h4>
                            <ul>
                                <li>1er Avenida # 108,
                                    Col Guerra, Guadalupe,
                                    Nuevo León. C.P. 67140
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>