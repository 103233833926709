<section class="about-wrap-layout7">
    <div class="container">
        <div class="about-box-layout9 single-service-box-layout1">
            <div class="col-lg-6 col-12">
                <div class="item-content">
                    <div class="item-services">
                        <h1 class="item-title titulo">Bienvenidos</h1>
                        <p>En Fengari contamos con un amplio catálogo de equipos, para
                            cualquier tipo de aplicación, manejamos las marcas más
                            reconocidas a nivel mundial, nuestro equipo de ventas está
                            capacitado para asesorarle en cualquier duda que pueda surgir
                            durante el proceso de selección del equipo adecuado a sus
                            necesidades.
                        </p>
                        <h4>
                            Las principales aplicaciones en las cuales podemos ayudarle son:
                        </h4>
                        <div class="service-list">
                            <ul>
                                <li><i class="fas fa-check"></i>Tratamiento de agua</li>
                                <li><i class="fas fa-check"></i>Transporte y distribución de agua</li>
                                <li><i class="fas fa-check"></i>Procesos químicos</li>
                                <li><i class="fas fa-check"></i>Procesos siderúrgicos</li>
                                <li><i class="fas fa-check"></i>Abastecimiento de agua</li>
                            </ul>
                            <ul>
                                <li><i class="fas fa-check"></i>Industria de la manufactura</li>
                                <li><i class="fas fa-check"></i>Petroleras</li>
                                <li><i class="fas fa-check"></i>Alimenticias</li>
                                <li><i class="fas fa-check"></i>Calderas</li>
                                <li><i class="fas fa-check"></i>Contra incendios</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <img src="./assets/img/inicio/tuberias_fondo.jpg" alt="Tuberias | Fengari">
            </div>
        </div>
    </div>
</section>
