<section class="single-project-wrap-layout">
    <div class="container">
        <div class="single-project-box-layout1">
            <div class="single-project-info">
                <div class="item-content">
                    <h1 class="heading-layout1">Bombas de vacío</h1>
                </div>
                <div class="row justify-content-between">
                    <div class="col-lg-8">
                        <div class="item-content">
                            <h3 class="item-title">BVD VACUUM</h3>
                            <p> BVD Vacuum es una marca de fabricacion nacional, 
                                cuenta con bombas de anillo liquido, etapa sencilla y 
                                doble etapa, asi como motobombas de vacio.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 offset-md-1">
                        <div class="single-project-slider">
                            <div class="rc-carousel nav-control-layout2 owl-carousel owl-loaded owl-drag" data-loop="true" data-items="10" 
                            data-margin="10" data-autoplay="false" data-autoplay-timeout="3000" data-smart-speed="1000" data-dots="false" 
                            data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true" data-r-x-small-dots="false" 
                            data-r-x-medium="1" data-r-x-medium-nav="true" data-r-x-medium-dots="false" data-r-small="1" data-r-small-nav="true"
                            data-r-small-dots="false" data-r-medium="1" data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="1" 
                            data-r-large-nav="true" data-r-large-dots="false" data-r-extra-large="1" data-r-extra-large-nav="true" 
                            data-r-extra-large-dots="false">
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_vacio/BVD/logo_bvd.png" alt="logo_bvd | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_vacio/BVD/anillo_liquido_bvd.png" alt="anillo_liquido_bvd | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_vacio/BVD/doble_etapa_bvd.png" alt="doble_etapa_bvd | Fengari">
                                </div>
                                <div class="slide-item">
                                    <img src="./assets/img/productos/bombas_vacio/BVD/motobomba_bvd.png" alt="motobomba_bvd | Fengari">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 offset-md-9">
                        <div class="about-box-layout4">
                            <div class="about-box-content">
                                <a href="./productos" class="ghost-btn-lg primary-border text-Primary mg-t-15"><i class="fas fa-angle-left"></i> Regresar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>