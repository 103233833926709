import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  titulo_banner;
  constructor(private route:ActivatedRoute) {
    this.titulo_banner = route.routeConfig.path;
  }
  ngOnInit(): void {

  } 

}
